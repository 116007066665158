import React, { useState, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import QrReader from 'react-qr-reader';
import DataStoreContext from 'dataStoreContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { IconQr } from 'resources/icons.js';
import HelperService from 'services/helpers.js';
import Nav from 'react-bootstrap/Nav';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import 'styles/scanOutletView.scss';

// Translation
import { useTranslation } from 'react-i18next';

export default function ScanOutletView({ outletChangedCB, DEBUG_sessionChangedCB, reloadTitleBarCB }) {
  const [outletId, setOutletId] = useState('');
  const [sessionId, setSessionId] = useState('');
  const [showScanner, setShowScanner] = useState(false);
  const { userMe, userSettings, isFlutterApp, sendFlutterNotification} = useContext(DataStoreContext);
  const [activeTab, setActiveTab]  = useState('RECENTLY_USED'); // RECENTLY_USED || MOST_USED

  // Translation
  const { t } = useTranslation('views');
  
  /* useEffect(() => {
        var d = document.getElementById("theinput");
        var topPos = d.offsetTop;
        var y = window.scrollY;
        const rootElement = document.documentElement;
    // eslint-disable-next-line react-hooks/exhaustive-deps     
    }, []);
*/

  const handleFormChange = (e) => {
    let val = e.target.value;

    if (/--$/.test(val)) val = val.slice(0, -1);
    
    const erasing = outletId.length > val.length;

    if ((val.length === 3 || val.length === 7) && !erasing) {
      val += '-';
    }

    setOutletId(val);

    if (val.match(/\d{3}-\d{3}-\d{3}/)) {
      e.target.blur();
      outletChangedCB(val);
      setOutletId('');
      return;
    }
  };

  const handleSessionIdFormChange = (e) => {
    let val = e.target.value;

    if (val.match(/\d{9}/)) {
      DEBUG_sessionChangedCB(val);
    }
    setSessionId(e.target.value);
  };

  /** On Huawei toggling keyboard makes component height corrupt.
   * We solve by reloading the titlebar */

  const handleBlur = (e) => {
    if (HelperService.getOS() === 'Android') {
      reloadTitleBarCB();
    }
  };

  const handleScan = (data) => {
    let outletId = HelperService.getPathOutletId(data);
    if (outletId && outletId.match(/\d{3}-\d{3}-\d{3}/)) {
      outletChangedCB(outletId);
    }
  };

  const handleScanError = (err) => {
    console.log(err);
  };

  const handleScanButtonClick = (showScanner) => {
    if (isFlutterApp) {
      let msg = showScanner ? '{"command": "SHOW_QR_SCANNER"}' : '{"command": "HIDE_QR_SCANNER"}';
      sendFlutterNotification(msg);
    } else {
      setShowScanner(showScanner);
    }
  };

    const renderUsageSection = () => {
        if ((!userMe || !userMe.usage || !userMe.usage.latestUsedOutlets || !userMe.usage.latestUsedOutlets.length > 0) &&
            (!userMe || !userMe.usage || !userMe.usage.mostUsedOutlets || !userMe.usage.mostUsedOutlets.length > 0)) return null;

        return (
            <div className="used-outlets">
                <Nav fill defaultActiveKey="RECENTLY_USED" onSelect={(selectedKey) => setActiveTab(selectedKey)} >
                    <Nav.Item>
                        <Nav.Link eventKey="RECENTLY_USED"><div className="nav-header"><span className="header-text">{t('scanOutletView.latest_used_outlets')}</span></div></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="MOST_USED"><div className="nav-header"><span className="header-text">{t('scanOutletView.most_used_outlets')}</span></div></Nav.Link>
                    </Nav.Item>
                </Nav>

                {(activeTab === 'RECENTLY_USED') ? renderLatestUsedOutlets() : null}
                {(activeTab === 'MOST_USED') ? renderMostUsedOutlets() : null}
            </div>
        );
    };
  
  const renderLatestUsedOutlets = () => {
        if (!userMe || !userMe.usage || !userMe.usage.latestUsedOutlets || !userMe.usage.latestUsedOutlets.length > 0) return null;

        return (
            <div className="used-outlet-block">

                {userMe.usage.latestUsedOutlets.map((outlet, index) =>
                    <div className="used-outlet"  key={index} onClick={ () => outletChangedCB(outlet.outletId)}>
                        <div className="used-outlet-left">
                            <div className="used-outlet-name">{outlet.name}</div>
			    <div className="used-outlet-name">{outlet.outletId}</div>
                            <div className="used-outlet-location">{outlet.locationName}</div>
                        </div>
                        <div className="used-outlet-button"><FontAwesomeIcon icon={faChevronRight} /></div> 
                    </div>
                )}
            </div>
        );
    }

    const renderMostUsedOutlets = () => {
        if (!userMe || !userMe.usage || !userMe.usage.mostUsedOutlets || !userMe.usage.mostUsedOutlets.length > 0) return null;

        return (
            <div className="used-outlet-block">

                {userMe.usage.mostUsedOutlets.map((outlet, index) =>
                    <div className="used-outlet" key={index}  onClick={ () => outletChangedCB(outlet.outletId)}>
                        <div className="used-outlet-left">
                            <div className="used-outlet-name">{outlet.name}</div>
			    <div className="used-outlet-name">{outlet.outletId}</div>
                            <div className="used-outlet-location">{outlet.locationName}</div>
                        </div>
                        <div className="used-outlet-button"><FontAwesomeIcon icon={faChevronRight} /></div> 
                    </div>
                )}
            </div>
        );
    }



  return (
    <div className="scan-outlet-view">
      <div className="header-row">{t('scanOutletView.enterOrScan')}</div>

      <div className="qr-text-input">
        <Form.Control id="theinput" className="outlet-input" size="lg" type="text" inputMode="numeric" placeholder="000-000-000" value={outletId} onChange={handleFormChange} onBlur={handleBlur} />
        {!showScanner ? (
          <div className="scan-button" onClick={() => handleScanButtonClick(true)}>
            <IconQr />
          </div>
        ) : null}
      </div>

      {showScanner ? (
        <div className="scan-container">
          <QrReader delay={300} onError={handleScanError} onScan={handleScan} style={{ width: '100%' }} />
          <div className="close-button" onClick={() => handleScanButtonClick(false)}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
      ) : null}

        { renderUsageSection() }

        <div style={{marginTop:'50px'}}></div>


      {userSettings && userSettings.devOptions && userSettings.devOptions.verboseInfo ? (
        <div>
          <div className="line-divider-with-title" style={{ marginTop: '30px' }}>
            <div className="line" />
            <span>Developer Mode</span>
            <div className="line" />
          </div>

          <div className="header-row">{t("scanOutletView.enterSessionId")}</div>
          <Form.Control className="outlet-input" size="lg" type="text" placeholder="000000000" value={sessionId} onChange={handleSessionIdFormChange} />
        </div>
      ) : null}
    </div>
  );
}
