import React, {useContext} from 'react';
import { useHistory } from "react-router-dom";
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import TitleBar from 'js/components/titleBar.js';
import DataStoreContext from 'dataStoreContext';
import HelperService from "services/helpers.js";
import 'styles/outletInfoView.scss'
import 'styles/profileView.scss'

// Translation
import { useTranslation } from 'react-i18next';

export default function OutletInfoView() {

    // Translation
    const { t } = useTranslation('views');
    
    let history = useHistory();
    //const { outletData} = useContext(DataStoreContext);
    const { scannedOutlet, userMe } = useContext(DataStoreContext);
    let LOGGED_IN_VIEW = userMe ? true : false;


    const handleBackClick = () => {
        history.push("/");
    }

    const renderPhases = (phases) => {
	if(phases === 1){
	    return <>{phases} {t("outletInfoView.phase")}</>
	} else {
	    return <>{phases} {t("outletInfoView.phases")}</>
	}
    };

    const countPhases = (outlet) => {
	let Phases = 1;
	if(outlet.phaseMapping){
	    if(outlet.phaseMapping.outletPhase2)
		Phases = 2;
	    if(outlet.phaseMapping.outletPhase3)
		Phases = 3;
	}
	return Phases;
    };

    const renderPropToupleValue = (scannedOutlet) => {
	let amps = HelperService.getMaxCharge_Amps(scannedOutlet);
	let phases = countPhases(scannedOutlet);
	return <>{amps} A, {((Number(230) * Number(amps) * Number(phases))/Number(1000)).toFixed(1)} kW, {renderPhases(phases)}</>
    }

    const renderOutletInfo = () => {
        console.log(scannedOutlet);
        return (
            <div className="prop-table">
                    <div className="bg-plate">
                        <h1>{t("outletInfoView.outlet")}</h1>

                        <div className="prop-touple">
                            <div className="prop-touple-label">
                            {t("outletInfoView.name")}
                            </div>
                            <div className="prop-touple-value">
                                {scannedOutlet.basicProvider.name}
                            </div>
                        </div>

                        <div className="prop-touple">
                            <div className="prop-touple-label">
                           {t("outletInfoView.size")}
                            </div>
                            <div className="prop-touple-value">
				{renderPropToupleValue(scannedOutlet)}
                            </div>
                        </div>

                        <div className="prop-touple">
                            <div className="prop-touple-label">
                           {t("outletInfoView.tariff")}
                            </div>
                            <div className="prop-touple-value">
                                { HelperService.getTariffRenderElement(LOGGED_IN_VIEW, scannedOutlet) } 
                            </div>
                        </div>

                        
                        

                    </div>
                </div>
        );
    }

    const renderSupportContact = () => {
        if (!scannedOutlet.basicProvider.supportContact) return '-';
        return (
            <>  
                {scannedOutlet.basicProvider.supportContact.name ? <span>{scannedOutlet.basicProvider.supportContact.name}</span> : null}
                {(scannedOutlet.basicProvider.supportContact.name && scannedOutlet.basicProvider.supportContact.phone) ? <span>, </span> : null}
                {scannedOutlet.basicProvider.supportContact.phone ? <span>{scannedOutlet.basicProvider.supportContact.phone}</span> : null}
                {scannedOutlet.basicProvider.supportContact.email ? <div>{scannedOutlet.basicProvider.supportContact.email}</div> : null}
            </>
        ); 
    }
    
    const renderProviderInfo = () => {
        return (
            <div className="prop-table">
                    <div className="bg-plate">
                        <h1>{t("outletInfoView.provider")}</h1>

                        <h2>
                            {scannedOutlet.basicProvider.name}
                        </h2>

                        <div className="prop-touple">
                            <div className="prop-touple-label">
                            {t("outletInfoView.support")}
                            </div>
                            <div className="prop-touple-value">
                                { renderSupportContact() }
                            </div>
                        </div>

                        
                        

                    </div>
                </div>
        );
    }

    return (
    <div className="sliding-view outlet-info-view">
        <div className="bg"></div>
        <div className="cover"></div>
        <TitleBar icon={faChevronLeft} clickCB={handleBackClick} title={'Uttag'}/>
        
        <div className="content">
            { scannedOutlet ? 
                <div>
                    {renderOutletInfo() }
                    { scannedOutlet.basicProvider ? renderProviderInfo() : null }
                </div> : 
                <div style={{height:'500px'}}>
                {t("outletInfoView.connectToOutlet")}
                </div>    
            }
        </div>
    </div>
  );
}
