import React from 'react';
import { useHistory } from "react-router-dom";
import HelperService from "services/helpers.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faChevronLeft, faBolt, faCaretSquareRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import 'styles/outletBaseInfo.scss';


export default function OutletBaseInfo({outlet, swapOutletCB}) {

    const { t } = useTranslation('views');
    
    let history = useHistory();

    if (!outlet) return null;

    //console.log(outlet);

    
const renderPhases = (phases) => {
    if(phases === 1){
	return <>{phases} {t("outletInfoView.phase")}</>
    } else {
	return <>{phases} {t("outletInfoView.phases")}</>
    }
};

    const countPhases = (outlet) => {
	let Phases = 1;
	if(outlet.phaseMapping){
	    if(outlet.phaseMapping.outletPhase2)
		Phases = 2;
	    if(outlet.phaseMapping.outletPhase3)
		Phases = 3;
	}
	return Phases;
    };

    const renderOB = (scannedOutlet) => {
	let amps = HelperService.getMaxCharge_Amps(scannedOutlet);
	let phases = countPhases(scannedOutlet);
	return <>{amps} A, {((Number(230) * Number(amps) * Number(phases))/Number(1000)).toFixed(1)} kW, {renderPhases(phases)}</>
    }


    return (
        <div className="outlet-base-info2">
            <div className="bg-plate">
                <div className="x-button outlet-disconnect-button" onClick={ () => swapOutletCB()}><FontAwesomeIcon icon={faChevronLeft} /></div>   
                <div className="center-group">          
                    <div className="top-row">          
                        <div className="ob-name" onClick={ () => { history.push("/provider")}}>{outlet.name}, {(outlet.basicProvider) ? outlet.basicProvider.name : '-'}</div>
                        <div className="ob-max-charge"><FontAwesomeIcon icon={faBolt} />{renderOB(outlet)}</div>
                    </div>
                    <div className="fee-row">
                    {(outlet.basicLocation) ? outlet.basicLocation.name : ''}
                    </div>
                    <div className="details-button-click-area" onClick={ () => history.push("/provider")}></div>    
                    <div className="details-button svg-border-btn" onClick={ () => history.push("/provider")}><FontAwesomeIcon icon={faCaretSquareRight} /></div>    
                </div>
            
            
            </div>
        </div>
    );

    /*
    <div className="outlet-base-info">
            <div className="ob-left">
            </div>
            <div className="ob-name-id-group">
                <div className="ob-name">{outlet.name}</div>
                <div className="ob-id">{outlet.outletId}</div>
            </div>
            <div className="ob-right">
                <div className="v-separator"></div>
                <div className="swap-outlet" onClick={ () => swapOutletCB()}>Byt uttag</div>
                <div className="more-info" onClick={ () => history.push('/000-000-000')}>Mer Info</div>
            </div>
        </div> 
        */
}
